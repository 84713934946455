
import { 
  getStorage, 
  ref as storageRef, 
  uploadBytes, 
  getDownloadURL,
  deleteObject 
} from 'firebase/storage';
import bcrypt from 'bcryptjs';
import { useState, useEffect, useCallback } from 'react';
import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged 
} from 'firebase/auth';
import { 
  getDatabase, 
  ref, 
  set, 
  get,
  push, 
  update, 
  remove,
  query,
  orderByChild,
  limitToLast,
  onValue,
  onDisconnect,
  serverTimestamp 
} from 'firebase/database';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import { getMessaging, getToken } from 'firebase/messaging';
import { getFunctions, httpsCallable } from 'firebase/functions';


const firebaseConfig = {
  apiKey: "AIzaSyDi2C1D2x9yah5E-VFg023CP7LpNjcaR9Y",
  authDomain: "chat-app-b7301.firebaseapp.com",
  databaseURL: "https://chat-app-b7301-default-rtdb.firebaseio.com",
  projectId: "chat-app-b7301",
  storageBucket: "chat-app-b7301.appspot.com",
  messagingSenderId: "507382479061",
  appId: "1:507382479061:web:7cbccdf256d96b81c64a5c",
  measurementId: "G-4SLQKG0XLD"
};

// Configure marked for safe markdown parsing
marked.setOptions({
  gfm: true,
  breaks: true,
  pedantic: false,
  sanitize: false,
  smartLists: true,
  smartypants: true
});

function arrayBufferToBase64(buffer) {
  const bytes = new Uint8Array(buffer);
  const binary = bytes.reduce((acc, byte) => acc + String.fromCharCode(byte), '');
  return btoa(binary);
}

// Add these helper functions at the top of firebaseService.js
function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}


class FirebaseService {
  constructor() {
    this.app = initializeApp(firebaseConfig);
    this.auth = getAuth(this.app);
    this.db = getDatabase(this.app);
    this.functions = getFunctions(this.app); // Add this line
    this.vapidKey = 'BOHSEjKc_pr8sE3oQ1SomeOrfiamOjTIKeZSf88ezFk_Eb2fYZRHqF1e3Wg5mbgdDGQHTKgzXp6ie0F1YrdDJeI';
    this.setupPresence();
    this.messageListeners = new Map();
    this.messaging = getMessaging(this.app);

  }
  async getFCMToken() {
    try {
      // First check if we have permission
      if (Notification.permission !== 'granted') {
        const permission = await Notification.requestPermission();
        if (permission !== 'granted') {
          throw new Error('Notification permission denied');
        }
      }

      // Ensure service worker is registered
      let serviceWorkerRegistration;
      try {
        serviceWorkerRegistration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
        console.log('Service Worker registered successfully');
      } catch (swError) {
        console.error('Service Worker registration failed:', swError);
        throw swError;
      }

      // Get FCM token
      const currentToken = await getToken(this.messaging, {
        vapidKey: 'BOHSEjKc_pr8sE3oQ1SomeOrfiamOjTIKeZSf88ezFk_Eb2fYZRHqF1e3Wg5mbgdDGQHTKgzXp6ie0F1YrdDJeI',
        serviceWorkerRegistration: serviceWorkerRegistration
      });

      if (!currentToken) {
        throw new Error('No registration token available');
      }

      // Save the token to the database
      if (this.auth.currentUser) {
        const tokenRef = ref(this.db, `users/${this.auth.currentUser.uid}/fcmToken`);
        await set(tokenRef, currentToken);
      }

      console.log('FCM Token obtained successfully');
      return currentToken;
    } catch (error) {
      console.error('FCM token retrieval failed', error);
      throw error;
    }
  }

  
  subscribeToUnreadCount(userId, callback) {
    if (!userId) return () => {};
    
    const userRef = ref(this.db, `users/${userId}/unreadCount`);
    const unsubscribe = onValue(userRef, (snapshot) => {
      const count = snapshot.val() || 0;
      callback(count);
    });
  
    return unsubscribe;
  }

  // Add this method to your FirebaseService class
async updateUserStatus(uid, status) {
  try {
    const statusRef = ref(this.db, `status/${uid}`);
    await update(statusRef, {
      ...status,
      lastUpdated: serverTimestamp()
    });
  } catch (error) {
    console.error('Error updating user status:', error);
    throw error;
  }
}

// Then modify your login method to handle errors better
async login(email, password) {
  try {
    const userCredential = await signInWithEmailAndPassword(this.auth, email, password);
    
    // Only update status if login was successful
    if (userCredential.user) {
      const userName = email.split('@')[0];
      try {
        await this.updateUserStatus(userCredential.user.uid, {
          online: true,
          lastSeen: serverTimestamp(),
          displayName: userName
        });
      } catch (statusError) {
        // Log the error but don't fail the login
        console.warn('Failed to update user status:', statusError);
      }
    }
    
    return userCredential.user;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
}



  // Message Processing Methods
  processMessageContent(message) {
    if (!message) return null;

    const processed = { ...message };

    try {
      // Process content based on message type and role
      if (message.role === 'assistant') {
        processed.type = 'markdown';
        if (message.content) {
          // Parse markdown and sanitize HTML
          const rawHtml = marked(message.content);
          processed.htmlContent = DOMPurify.sanitize(rawHtml);
        }
      } else {
        processed.type = 'text';
        if (message.content) {
          // Basic text processing for user messages
          processed.htmlContent = DOMPurify.sanitize(message.content)
            .replace(/\n/g, '<br>')
            .replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>');
        }
      }

      // Ensure all messages have required fields
      processed.timestamp = message.timestamp || Date.now();
      processed.status = message.status || 'complete';
      processed.type = processed.type || 'text';
      processed.content = message.content || '';

      return processed;
    } catch (error) {
      console.error('Error processing message content:', error);
      return message; // Return original message if processing fails
    }
  }

  // Message Management Methods
  async createStreamingMessage(userId, initialContent = '', role = 'assistant') {
    if (!userId) throw new Error('User ID is required');

    try {
      console.log('Creating streaming message for:', userId);
      
      const messageRef = push(ref(this.db, `assistantChat/${userId}`));
      const messageData = {
        role,
        content: initialContent,
        type: 'markdown',
        timestamp: serverTimestamp(),
        status: 'streaming',
        updatedAt: serverTimestamp()
      };

      await set(messageRef, messageData);
      console.log('Created streaming message:', messageRef.key);
      return messageRef.key;
    } catch (error) {
      console.error('Error creating streaming message:', error);
      throw error;
    }
  }

  processMessageContent(message) {
    if (!message) return null;

    const processed = { ...message };

    try {
      if (message.role === 'assistant') {
        processed.type = 'markdown';
        if (message.content) {
          // Parse markdown and sanitize HTML
          const rawHtml = marked.parse(message.content);
          processed.htmlContent = DOMPurify.sanitize(rawHtml);
        }
      } else {
        processed.type = 'text';
        if (message.content) {
          // Basic text processing for user messages
          processed.htmlContent = DOMPurify.sanitize(message.content)
            .replace(/\n/g, '<br>')
            .replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>');
        }
      }

      // Ensure valid timestamp
      if (message.timestamp) {
        processed.timestamp = typeof message.timestamp === 'number' 
          ? message.timestamp 
          : message.timestamp.seconds 
            ? message.timestamp.seconds * 1000 
            : Date.now();
      }

      return processed;
    } catch (error) {
      console.error('Error processing message content:', error);
      return message; // Return original message if processing fails
    }
  }

  // Streaming Message Methods
  async createStreamingMessage(userId, initialContent = '', role = 'assistant') {
    if (!userId) throw new Error('User ID is required');
    
    console.log('Creating streaming message for:', userId);
    
    const messageRef = push(ref(this.db, `assistantChat/${userId}`));
    const messageData = {
      role,
      content: initialContent,
      type: 'markdown',
      timestamp: serverTimestamp(),
      status: 'streaming',
      updatedAt: serverTimestamp()
    };

    try {
      await set(messageRef, messageData);
      console.log('Created streaming message:', messageRef.key);
      return messageRef.key;
    } catch (error) {
      console.error('Error creating streaming message:', error);
      throw error;
    }
  }

  async updateStreamingMessage(userId, messageId, content, final = false) {
    if (!userId || !messageId) throw new Error('User ID and message ID are required');
    
    console.log('Updating streaming message:', { userId, messageId, contentLength: content?.length, final });
    
    const messagePath = `assistantChat/${userId}/${messageId}`;
    
    try {
      // Get current message data
      const messageRef = ref(this.db, messagePath);
      const snapshot = await get(messageRef);
      const currentData = snapshot.val();
      
      // Only update if content has changed
      if (!currentData || currentData.content !== content) {
        const updateData = {
          content,
          status: final ? 'complete' : 'streaming',
          updatedAt: serverTimestamp()
        };

        await update(messageRef, updateData);
        console.log('Updated streaming message successfully');
      }
    } catch (error) {
      console.error('Error updating streaming message:', error);
      throw error;
    }
  }

  // Regular Message Methods
  async sendAssistantMessage(userId, content, role, options = {}) {
    if (!userId) throw new Error('User ID is required');
    
    console.log('Creating message:', { userId, role, contentLength: content?.length, options });

    const messageRef = push(ref(this.db, `assistantChat/${userId}`));
    const messageData = {
      content: content || '',
      role,
      timestamp: serverTimestamp(),
      type: options.type || (role === 'assistant' ? 'markdown' : 'text'),
      status: options.status || 'complete',
      updatedAt: serverTimestamp(),
      ...options
    };

    try {
      await set(messageRef, messageData);
      console.log('Message created successfully:', messageRef.key);
      return messageRef.key;
    } catch (error) {
      console.error('Error sending message:', error);
      throw error;
    }
  }

  async updateMessage(userId, messageId, updates) {
    if (!userId || !messageId) throw new Error('User ID and message ID are required');
    
    console.log('Starting message update:', { userId, messageId, updates });
  
    const messagePath = `assistantChat/${userId}/${messageId}`;
    const updateData = {
      ...updates,
      updatedAt: serverTimestamp()
    };
  
    try {
      await update(ref(this.db, messagePath), updateData);
      console.log('✅ Message updated successfully:', {
        path: messagePath,
        updates: updateData
      });
    } catch (error) {
      console.error('❌ Message update failed:', error);
      throw error;
    }
  }

  async deleteMessage(chatType, messageId, userId = null) {
    if (!messageId) throw new Error('Message ID is required');
    if (chatType === 'assistant' && !userId) throw new Error('User ID is required for assistant chat');

    try {
      const path = chatType === 'family' 
        ? `familyChat/${messageId}`
        : `assistantChat/${userId}/${messageId}`;
      
      const messageRef = ref(this.db, path);
      await remove(messageRef);
      console.log('Message deleted successfully:', { chatType, messageId, userId });
    } catch (error) {
      console.error('Error deleting message:', error);
      throw error;
    }
  }

  // Subscription Methods
  subscribeAssistantChat(userId, callback) {
    if (!userId) throw new Error('User ID is required');
  
    console.log('Setting up chat subscription for:', userId);
    const chatRef = ref(this.db, `assistantChat/${userId}`);
    
    const unsubscribe = onValue(chatRef, (snapshot) => {
      try {
        const messages = [];
        snapshot.forEach((childSnapshot) => {
          const message = {
            id: childSnapshot.key,
            ...childSnapshot.val()
          };
          messages.push(this.processMessageContent(message));
        });
        
        // Sort messages by timestamp
        const sortedMessages = messages.sort((a, b) => {
          const timeA = a.timestamp || 0;
          const timeB = b.timestamp || 0;
          return timeA - timeB;
        });
        
        console.log('📥 Chat subscription update:', {
          messageCount: sortedMessages.length,
          lastMessage: sortedMessages[sortedMessages.length - 1]?.content?.substring(0, 50) + '...'
        });
        
        callback(sortedMessages);
      } catch (error) {
        console.error('Error processing chat subscription update:', error);
      }
    }, (error) => {
      console.error('❌ Chat subscription error:', error);
    });
  
    return unsubscribe;
  }


  
  async requestNotificationPermission() {
    // Check if Notifications are supported
    if (!('Notification' in window)) {
      console.warn('This browser does not support desktop notifications');
      return false;
    }
  
    // If already granted, return true
    if (Notification.permission === 'granted') {
      return true;
    }
  
    // If not denied, request permission
    if (Notification.permission !== 'denied') {
      try {
        const permission = await Notification.requestPermission();
        return permission === 'granted';
      } catch (error) {
        console.error('Error requesting notification permission:', error);
        return false;
      }
    }
  
    // If previously denied, return false
    return false;
  }
  
// In firebaseService.js

async sendFamilyMessage(userId, content, type = 'text', options = {}) {
  if (!this.auth.currentUser) {
    throw new Error('Must be logged in to send messages');
  }

  try {
    const messageRef = ref(this.db, 'familyChat');
    const newMessageRef = push(messageRef);
    
    // Capitalize username
    const rawUsername = this.auth.currentUser?.displayName 
      || this.auth.currentUser?.email?.split('@')[0] 
      || 'Anonymous';
    const userName = rawUsername.charAt(0).toUpperCase() + rawUsername.slice(1);
    
    const message = {
      content: content.trim(),
      userId: this.auth.currentUser.uid,
      timestamp: serverTimestamp(),
      type: type || 'text',
      userName,
      userPhoto: this.auth.currentUser?.photoURL || null,
      ...options
    };

    await set(newMessageRef, message);

    // Send both push and email notifications
    try {
      // 1. Send push notification
      // Inside sendFamilyMessage function, update the push notification section:
await fetch('https://api.onesignal.com/notifications?c=push', {
  method: 'POST',
  headers: {
    'Accept': 'application/json',
    'Authorization': 'os_v2_app_fl7ridfkw5eurkmijwih2adwh5wv2dgsywyugu575zd7tyvynyrkzhjnolpbmezqjsxiodit4k4yr2cv45d4xjkfpo7r3r7fwpjgcvq',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    app_id: '2aff140c-aab7-4948-a988-4d907d00763f',
    contents: {
      en: `${message.userName}: ${content.trim()}`
    },
    headings: {
      en: 'New Bravidoff Message'
    },
    included_segments: ['Family'],
    ios_badgeType: "Increase",
  ios_badgeCount: 1,
  safari_badge_count: 1,
  chrome_web_badge: "1",
  priority: 10,
  ios_relevance_score: 5,
    ios_interruption_level: "critical",  // Ensure timely delivery
    ios_badgeType: "Increase",  // Increment badge count
    ios_sound: "default",  // Use default notification sound
    safari_web_push: {
      badge_count: 1,
      action_button: "View",
      ttl: 86400  // Time to live - 24 hours
    },
    
  })
});

      // 2. Send email notification
      await fetch('https://api.onesignal.com/notifications?c=email', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'os_v2_app_fl7ridfkw5eurkmijwih2adwh5wv2dgsywyugu575zd7tyvynyrkzhjnolpbmezqjsxiodit4k4yr2cv45d4xjkfpo7r3r7fwpjgcvq',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          app_id: '2aff140c-aab7-4948-a988-4d907d00763f',
          email_subject: `💬New Bravidoff Message from ${message.userName}`,
          email_preheader: ` ${message.userName}: ${content.trim()} `,
          email_from_name: 'GoFamily!',
          "include_email_tokens": [
    "gofamilyalerts@outlook.com"
  ],
          email_body: `<!DOCTYPE html>
<html>
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<body style="margin: 0; padding: 0; background-color: #f5f7fa;">
    <div style="max-width: 600px; margin: 20px auto; background-color: #ffffff; border-radius: 15px; padding: 30px; box-shadow: 0 2px 10px rgba(0,0,0,0.08); font-family: 'Helvetica Neue', Arial, sans-serif;">
        
        <!-- Warm Greeting -->
        <div style="text-align: center; margin-bottom: 25px;">
            <img src= "${this.auth.currentUser?.photoURL}"  alt="💌" style="width: 60px; height: 60px; margin-bottom: 15px;">
            <h2 style="color: #4B0082; margin: 0; font-size: 24px; font-weight: 500;">A Message from ${message.userName}</h2>
        </div>

        <!-- Message Content -->
        <div style="background-color: #f8f5ff; border-radius: 12px; padding: 25px; margin: 20px 0; border-left: 4px solid #4B0082;">
            <p style="color: #444; font-size: 16px; line-height: 1.6; margin: 0;">${content.trim()}</p>
        </div>

        <!-- Action Button -->
        <div style="text-align: center; margin-top: 30px;">
            <a href="https://chat-app-b7301.web.app/family" 
               style="display: inline-block; padding: 12px 28px; 
                      background-color: #4B0082; color: white; 
                      text-decoration: none; border-radius: 25px;
                      font-size: 16px; font-weight: 500;
                      transition: background-color 0.3s ease;">
                Open in GoFamily ✨
            </a>
        </div>

        <!-- Footer Note -->
        <p style="text-align: center; color: #888; font-size: 14px; margin-top: 25px;">
            Bringing families closer, one message at a time 💝
        </p>
    </div>
</body>
</html>`
        })
      });
      
    } catch (notificationError) {
      // Log but don't throw - we don't want to fail the message send if notification fails
      console.error('OneSignal Notification Error:', notificationError);
    }

    return newMessageRef.key;
  } catch (error) {
    console.error('Error sending family message:', error);
    throw error;
  }
}
  
async setupNotifications() {
  try {
    if (!window.OneSignal) {
      console.warn('OneSignal not loaded');
      return false;
    }

    if (!this.auth.currentUser) {
      console.warn('No user logged in');
      return false;
    }

    // Set external user ID to match Firebase UID
    await window.OneSignal.setExternalUserId(this.auth.currentUser.uid);
    
    // Get OneSignal Player ID for backwards compatibility
    const playerId = await window.OneSignal.getUserId();
    
    if (playerId) {
      // Store in Firebase
      const userRef = ref(this.db, `users/${this.auth.currentUser.uid}`);
      await update(userRef, {
        oneSignalId: playerId,
        notificationsEnabled: true,
        lastUpdated: serverTimestamp()
      });
    }

    // Register for push if not already subscribed
    const isSubscribed = await window.OneSignal.isPushNotificationsEnabled();
    if (!isSubscribed) {
      await window.OneSignal.registerForPushNotifications();
    }

    return true;
  } catch (error) {
    console.error('Error setting up OneSignal:', error);
    return false;
  }
}
  
  // Simplified notification state tracking
  async createNotification(userId, data) {
    if (!this.auth.currentUser) return;
    
    try {
      const notificationRef = ref(this.db, `notifications/${this.auth.currentUser.uid}/${data.messageId}`);
      await set(notificationRef, {
        messageId: data.messageId,
        state: 'unread',
        timestamp: serverTimestamp()
      });
    } catch (error) {
      console.warn('Error tracking notification state:', error);
    }
  }

// Add this method to FirebaseService class
async markAllNotificationsAsRead() {
  if (!this.auth.currentUser) return;
  
  try {
    const notificationsRef = ref(this.db, `notifications/${this.auth.currentUser.uid}`);
    const snapshot = await get(notificationsRef);
    
    if (!snapshot.exists()) return;

    const updates = {};
    snapshot.forEach((childSnapshot) => {
      updates[`${childSnapshot.key}/state`] = 'read';
    });

    await update(notificationsRef, updates);
  } catch (error) {
    console.warn('Error marking notifications as read:', error);
  }
}

  
  async getWebPushSubscription(registration) {
    try {
      return await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(this.vapidKey)
      });
    } catch (error) {
      console.warn('Web Push subscription failed', error);
      return null;
    }
  }
  
  async getFCMToken(registration) {
    try {
      const messaging = getMessaging(this.app);
      return await getToken(messaging, {
        vapidKey: this.vapidKey,
        serviceWorkerRegistration: registration
      });
    } catch (error) {
      console.warn('FCM token retrieval failed', error);
      return null;
    }
  }
  
  // Utility method to convert VAPID key
  urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');
  
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
  
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
  subscribeFamilyChat(callback, limit = 50) {
    if (!this.auth.currentUser) {
      console.log('No user logged in');
      return () => {};
    }

    const chatRef = query(
      ref(this.db, 'familyChat'),
      orderByChild('timestamp'),
      limitToLast(limit)
    );

    let lastKnownMessage = null;
    let visibilityHandler = null;
    
    // Set up visibility change handler
   const setupVisibilityHandler = () => {
  const handler = async () => {
    if (document.visibilityState === 'visible') {
      try {
        // Clear badge when app becomes visible
        if ('clearAppBadge' in navigator) {
          await navigator.clearAppBadge();
        }
        // Only call if method exists
        if (typeof this.markAllNotificationsAsRead === 'function') {
          await this.markAllNotificationsAsRead();
        }
      } catch (error) {
        console.warn('Error handling visibility change:', error);
      }
    }
  };

  document.addEventListener('visibilitychange', handler);
  visibilityHandler = handler;
};
    setupVisibilityHandler();

    const unsubscribe = onValue(chatRef, async (snapshot) => {
      const messages = [];
      snapshot.forEach((childSnapshot) => {
        const message = {
          id: childSnapshot.key,
          ...childSnapshot.val(),
          type: childSnapshot.val().type || 'text'
        };
        messages.push(message);
      });

      messages.sort((a, b) => (a.timestamp || 0) - (b.timestamp || 0));

      if (messages.length > 0) {
        const latestMessage = messages[messages.length - 1];
        
        // Handle new messages when they arrive
        if (latestMessage.userId !== this.auth.currentUser.uid && 
            (!lastKnownMessage || latestMessage.id !== lastKnownMessage.id)) {
          
          if (document.visibilityState !== 'visible') {
            try {
              // Create notification entry
              await this.createNotification(this.auth.currentUser.uid, {
                messageId: latestMessage.id,
                content: latestMessage.content,
                senderId: latestMessage.userId
              });

              // Show browser notification if permitted
              if (Notification.permission === 'granted') {
                const notification = new Notification('New Family Message', {
                  body: latestMessage.content,
                  icon: '/logo192.png',
                  tag: 'family-chat',
                  requireInteraction: true,
                  silent: false,
                  renotify: true
                });

                notification.onclick = async () => {
                  window.focus();
                  notification.close();
                  await this.markAllNotificationsAsRead();
                };
              }
            } catch (error) {
              console.error('Error handling new message:', error);
            }
          }
        }
      }

      lastKnownMessage = messages.length > 0 ? messages[messages.length - 1] : null;
      callback(messages);
    });

    return () => {
      unsubscribe();
      if (visibilityHandler) {
        document.removeEventListener('visibilitychange', visibilityHandler);
      }
    };
  }


  
    // Method to delete family messages
    async deleteFamilyMessage(messageId) {
      try {
        const messageRef = ref(this.db, `familyChat/${messageId}`);
        await remove(messageRef);
      } catch (error) {
        console.error('Error deleting family message:', error);
        throw error;
      }
    }

  // Enhanced Message Operations
  async updateMessage(userId, messageId, updates, type = 'assistant') {
    console.log('UpdateMessage called with:', { 
      userId, 
      messageId, 
      updates, 
      type 
    });

    try {
      const path = type === 'family' 
        ? `familyChat/${messageId}`
        : `assistantChat/${userId}/${messageId}`;
      
      console.log('Using Firebase path:', path);

      // Process content if it's being updated
      if (updates.content) {
        console.log('Processing content:', updates.content);
        
        const processedMessage = this.processMessageContent({
          role: type === 'assistant' ? 'assistant' : 'user',
          content: updates.content
        });
        
        console.log('Processed message:', processedMessage);
        
        updates.htmlContent = processedMessage.htmlContent;
        updates.type = processedMessage.type;
      }

      const finalUpdates = {
        ...updates,
        updatedAt: serverTimestamp()
      };

      console.log('Final updates to be sent to Firebase:', finalUpdates);

      await update(ref(this.db, path), finalUpdates);
      console.log('Firebase update successful');

      return finalUpdates;  // Return the updates for debugging

    } catch (error) {
      console.error('Error updating message:', error);
      console.error('Error details:', {
        path: type === 'family' 
          ? `familyChat/${messageId}`
          : `assistantChat/${userId}/${messageId}`,
        updates,
        error
      });
      throw error;
    }
  }



  async logout() {
    try {
      const uid = this.auth.currentUser?.uid;
      if (uid) {
        await this.updateUserStatus(uid, {
          online: false,
          lastSeen: serverTimestamp()
        });
      }
      await signOut(this.auth);
    } catch (error) {
      console.error('Logout error:', error);
      throw error;
    }
  }
  // Document handling methods for FirebaseService class

async uploadFamilyDocument(file, metadata) {
  try {
    const storage = getStorage();
    const user = this.auth.currentUser;
    
    if (!user) {
      throw new Error('User not authenticated');
    }
    
    // Create a unique filename
    const filename = `family-vault/${Date.now()}_${file.name}`;
    const storageReference = storageRef(storage, filename);
    
    // Upload to Storage
    const uploadResult = await uploadBytes(storageReference, file);
    const downloadURL = await getDownloadURL(uploadResult.ref);
    
    // Save metadata to Database
    const documentRef = ref(this.db, 'familyVault/documents');
    const newDocRef = push(documentRef);
    
    const documentData = {
      name: metadata.name || file.name,
      uploadedBy: metadata.uploadedBy || user.email,
      uploadedAt: metadata.uploadedAt || new Date().toISOString(),
      fileURL: downloadURL,
      fileType: file.type,
      fileSize: file.size,
      filename: filename
    };

    await set(newDocRef, documentData);
    return { id: newDocRef.key, ...documentData };
  } catch (error) {
    console.error('Document upload error:', error);
    throw error;
  }
}

async listFamilyDocuments() {
  try {
    const documentsRef = ref(this.db, 'familyVault/documents');
    return get(documentsRef);
  } catch (error) {
    console.error('Error fetching family documents:', error);
    throw error;
  }
}

async deleteDocument(documentId) {
  try {
    const docRef = ref(this.db, `familyVault/documents/${documentId}`);
    
    // Get document data first
    const snapshot = await get(docRef);
    const docData = snapshot.val();
    
    if (!docData) {
      throw new Error('Document not found');
    }
    
    // Delete from storage if filename exists
    if (docData.filename) {
      const storage = getStorage();
      const storageRef = ref(storage, docData.filename);
      await deleteObject(storageRef);
    }
    
    // Delete from database
    await remove(docRef);
  } catch (error) {
    console.error('Document deletion error:', error);
    throw error;
  }
}

async setVaultPasscode(passcode) {
  try {
    // Store the passcode directly in the database
    // Note: In a production environment, you'd want to hash this
    const vaultRef = ref(this.db, 'familyVault/passcode');
    await set(vaultRef, passcode);
  } catch (error) {
    console.error('Error setting vault passcode:', error);
    throw error;
  }
}

async verifyVaultPasscode(attemptedPasscode) {
  try {
    const vaultRef = ref(this.db, 'familyVault/passcode');
    const snapshot = await get(vaultRef);
    return snapshot.val() === attemptedPasscode;
  } catch (error) {
    console.error('Error verifying passcode:', error);
    throw error;
  }
}

async getVaultPasscode() {
  try {
    const vaultRef = ref(this.db, 'familyVault/passcode');
    const snapshot = await get(vaultRef);
    return snapshot.val();
  } catch (error) {
    console.error('Error getting vault passcode:', error);
    throw error;
  }
}

// High Scores Methods
async saveHighScore(userId, scoreData) {
  try {
    const scoreRef = ref(this.db, `highScores/soccer`);
    const newScoreRef = push(scoreRef);
    
    const score = {
      userId,
      userName: this.auth.currentUser?.displayName || 'Anonymous',
      questions: scoreData.questions,
      points: scoreData.points,
      timestamp: serverTimestamp(),
      date: new Date().toISOString()
    };

    await set(newScoreRef, score);

    // Get and return top scores
    return this.getHighScores();
  } catch (error) {
    console.error('Error saving high score:', error);
    throw error;
  }
}

async getHighScores(limit = 5) {
  try {
    const scoresRef = query(
      ref(this.db, 'highScores/soccer'),
      orderByChild('points'),
      limitToLast(limit)
    );
    
    const snapshot = await get(scoresRef);
    const scores = [];
    
    snapshot.forEach((childSnapshot) => {
      scores.push({
        id: childSnapshot.key,
        ...childSnapshot.val()
      });
    });

    // Sort by points in descending order
    return scores.sort((a, b) => b.points - a.points);
  } catch (error) {
    console.error('Error getting high scores:', error);
    return [];
  }
}

// Subscribe to high scores updates
subscribeToHighScores(callback, limit = 5) {
  const scoresRef = query(
    ref(this.db, 'highScores/soccer'),
    orderByChild('points'),
    limitToLast(limit)
  );

  const unsubscribe = onValue(scoresRef, (snapshot) => {
    const scores = [];
    snapshot.forEach((childSnapshot) => {
      scores.push({
        id: childSnapshot.key,
        ...childSnapshot.val()
      });
    });

    // Sort by points in descending order
    callback(scores.sort((a, b) => b.points - a.points));
  });


}
  setupPresence() {
    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        const userStatusRef = ref(this.db, `status/${user.uid}`);
        const connectedRef = ref(this.db, '.info/connected');

        onValue(connectedRef, (snapshot) => {
          if (snapshot.val() === true) {
            onDisconnect(userStatusRef).update({
              online: false,
              lastSeen: serverTimestamp()
            });

            update(userStatusRef, {
              online: true,
              lastSeen: serverTimestamp()
            });
          }
        });
      }
    });
  }

  
}



export const firebaseService = new FirebaseService();

// React hook for Firebase integration
export const useFirebase = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseService.auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => {
      unsubscribe();
      firebaseService.cleanup();
    };
  }, []);

  const sendMessage = useCallback(async (content, options = {}) => {
    if (!user) {
      setError(new Error('User not authenticated'));
      return;
    }

    try {
      return await firebaseService.sendAssistantMessage(user.uid, content, 'user', options);
    } catch (error) {
      setError(error);
      throw error;
    }
  }, [user]);

  return {
    user,
    loading,
    error,
    sendMessage,
    service: firebaseService
  };
};

export default firebaseService;
window.firebaseService = firebaseService;