// ai21Service.js
import * as msal from '@azure/msal-browser';
import { calendarService } from '../knowledge/calendarService';

class AI21Service {
  constructor() {
    this.controller = null;
    this.apiKey = this.getApiKey();
    this.msalInitialized = false;
    this.msalInstance = null;

    // Define the calendar function schema
    this.calendarFunctionSchema = {
      type: "function",
      function: {
        name: "create_calendar_event",
        description: "Create a calendar event (single or recurring) for the family using Microsoft Graph API",
        parameters: {
          type: "object",
          properties: {
            subject: {
              type: "string",
              description: "The title/subject of the event"
            },
            start: {
              type: "string",
              description: "Start time of the event in ISO format (e.g., 2024-04-10T12:00:00)"
            },
            end: {
              type: "string",
              description: "End time of the event in ISO format (e.g., 2024-04-10T14:00:00)"
            },
            location: {
              type: "string",
              description: "Location of the event"
            },
            description: {
              type: "string",
              description: "Description or body of the event"
            },
            attendees: {
              type: "array",
              items: {
                type: "string"
              },
              description: "Email addresses of attendees"
            },
            recurrence: {
              type: "object",
              description: "Recurrence pattern for the event",
              properties: {
                pattern: {
                  type: "object",
                  properties: {
                    type: {
                      type: "string",
                      enum: ["daily", "weekly", "monthly", "yearly"],
                      description: "Type of recurrence pattern"
                    },
                    interval: {
                      type: "integer",
                      description: "Number of units between occurrences (e.g., every 2 weeks)"
                    },
                    daysOfWeek: {
                      type: "array",
                      items: {
                        type: "string",
                        enum: ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
                      },
                      description: "Days of the week for weekly recurrence"
                    },
                    dayOfMonth: {
                      type: "integer",
                      description: "Day of the month for monthly recurrence"
                    }
                  },
                  required: ["type"]
                },
                range: {
                  type: "object",
                  properties: {
                    type: {
                      type: "string",
                      enum: ["noEnd", "endDate", "numbered"],
                      description: "How the recurrence ends"
                    },
                    startDate: {
                      type: "string",
                      description: "Start date of the recurrence"
                    },
                    endDate: {
                      type: "string",
                      description: "End date of the recurrence (if type is endDate)"
                    },
                    numberOfOccurrences: {
                      type: "integer",
                      description: "Number of occurrences (if type is numbered)"
                    }
                  },
                  required: ["type", "startDate"]
                }
              }
            }
          },
          required: ["subject", "start", "end"]
        }
      }
    };
  }

  // Add to ai21Service.js
  isMobileDevice() {
    return (
      /Android/i.test(navigator.userAgent) ||
      /iPhone|iPad|iPod/i.test(navigator.userAgent)
    );
  }

  async handleMobileAuth(scopes) {
    try {
      // For mobile, we'll open in a new tab/window
      const loginRequest = {
        scopes: scopes,
        prompt: 'select_account',
        redirectUri: window.location.origin + '/auth-callback' // Make sure this route exists
      };

      // Store the current state so we can resume after auth
      sessionStorage.setItem('pendingAuthRequest', JSON.stringify({
        timestamp: Date.now(),
        scopes: scopes
      }));

      // Use redirect for mobile
      await this.msalInstance.loginRedirect(loginRequest);
      
      // This point won't be reached immediately as page will redirect
      return null;
    } catch (error) {
      console.error('Mobile auth error:', error);
      throw error;
    }
  }

  async handleAuthFlow(scopes = ['Calendars.ReadWrite']) {
    if (!this.msalInitialized) {
      await this.initializeMsal();
    }

    const accounts = this.msalInstance.getAllAccounts();
    let accessToken;

    try {
      if (accounts.length === 0) {
        if (this.isMobileDevice()) {
          return await this.handleMobileAuth(scopes);
        } else {
          // Desktop flow remains the same with popup
          const loginResponse = await this.msalInstance.loginPopup({
            scopes: scopes,
            prompt: 'select_account'
          });
          
          if (loginResponse) {
            const tokenResponse = await this.msalInstance.acquireTokenSilent({
              scopes: scopes,
              account: loginResponse.account
            });
            accessToken = tokenResponse.accessToken;
          }
        }
      } else {
        // Try silent token acquisition first
        try {
          const response = await this.msalInstance.acquireTokenSilent({
            scopes: scopes,
            account: accounts[0]
          });
          accessToken = response.accessToken;
        } catch (silentError) {
          if (silentError instanceof msal.InteractionRequiredAuthError) {
            if (this.isMobileDevice()) {
              return await this.handleMobileAuth(scopes);
            } else {
              const loginResponse = await this.msalInstance.loginPopup({
                scopes: scopes
              });
              
              if (loginResponse) {
                const tokenResponse = await this.msalInstance.acquireTokenSilent({
                  scopes: scopes,
                  account: loginResponse.account
                });
                accessToken = tokenResponse.accessToken;
              }
            }
          } else {
            throw silentError;
          }
        }
      }

      return accessToken;
    } catch (error) {
      console.error('Auth flow error:', error);
      throw error;
    }
  }

  getApiKey() {
    if (window._env_ && window._env_.REACT_APP_OPENAI_API_KEY) {
      return window._env_.REACT_APP_OPENAI_API_KEY;
    }
    return process.env.REACT_APP_OPENAI_API_KEY;
  }

  async initializeMsal() {
    if (this.msalInitialized) return;

    this.msalInstance = new msal.PublicClientApplication({
      auth: {
        clientId: '40886084-f0d8-45e3-b842-3d4f10ce2e5b', 
        authority: 'https://login.microsoftonline.com/common',
        redirectUri: window.location.origin,
      },
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
      }
    });

    await this.msalInstance.initialize();
    this.msalInitialized = true;
  }

  async getJewishContext(userMessage = '') {
    const isJewishQuery = userMessage.toLowerCase().includes('parsha') || 
                         userMessage.toLowerCase().includes('torah') ||
                         userMessage.toLowerCase().includes('shabbat') ||
                         userMessage.toLowerCase().includes('shabbos');

    if (!isJewishQuery) return '';

    try {
      const calendarData = await calendarService.getJewishCalendarInfo();
      if (!calendarData?.items) return '';

      let contextString = '';

      const parsha = calendarData.items.find(item => item.category === 'parashat');
      const candleLighting = calendarData.items.find(item => item.category === 'candles');
      const havdalah = calendarData.items.find(item => item.category === 'havdalah');

      if (parsha) {
        contextString += `\nCurrent Parsha: ${parsha.title}`;
      }

      if (candleLighting || havdalah) {
        contextString += '\n\nShabbat Times (Johannesburg):';
        if (candleLighting) {
          contextString += `\n${candleLighting.title}`;
        }
        if (havdalah) {
          contextString += `\n${havdalah.title}`;
        }
      }

      return contextString.trim();
    } catch (error) {
      console.error('Error getting Jewish context:', error);
      return '';
    }
  }

  async handleCalendarFunction(functionArgs) {
    try {
      const accessToken = await this.handleAuthFlow(['Calendars.ReadWrite']);
      
      // If no token and on mobile, we're probably redirecting - save event details
      if (!accessToken && this.isMobileDevice()) {
        // Store event details to create after auth
        sessionStorage.setItem('pendingCalendarEvent', JSON.stringify({
          timestamp: Date.now(),
          eventDetails: functionArgs
        }));
        
        return {
          success: false,
          error: 'Please complete authentication and try again',
          requiresAuth: true
        };
      }

      if (!accessToken) {
        throw new Error('Failed to get access token');
      }

      const eventObject = {
        subject: functionArgs.subject,
        body: {
          contentType: "HTML",
          content: functionArgs.description || functionArgs.subject
        },
        start: {
          dateTime: functionArgs.start,
          timeZone: "Africa/Johannesburg"
        },
        end: {
          dateTime: functionArgs.end,
          timeZone: "Africa/Johannesburg"
        },
        location: functionArgs.location ? {
          displayName: functionArgs.location
        } : undefined,
        attendees: (functionArgs.attendees || []).map(email => ({
          emailAddress: {
            address: email,
            name: email.split('@')[0]
          },
          type: "required"
        })),
        recurrence: functionArgs.recurrence ? {
          pattern: {
            type: functionArgs.recurrence.pattern.type,
            interval: functionArgs.recurrence.pattern.interval || 1,
            daysOfWeek: functionArgs.recurrence.pattern.daysOfWeek,
            dayOfMonth: functionArgs.recurrence.pattern.dayOfMonth
          },
          range: {
            type: functionArgs.recurrence.range.type,
            startDate: functionArgs.recurrence.range.startDate,
            endDate: functionArgs.recurrence.range.endDate,
            numberOfOccurrences: functionArgs.recurrence.range.numberOfOccurrences
          }
        } : undefined
      };

      const response = await fetch('https://graph.microsoft.com/v1.0/me/events', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(eventObject)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error?.message || 'Failed to create event');
      }

      const result = await response.json();
      return {
        success: true,
        eventId: result.id,
        webLink: result.webLink,
        message: `Event "${functionArgs.subject}" has been created successfully`
      };
    } catch (error) {
      console.error('Calendar function error:', error);
      return {
        success: false,
        error: error.message || 'Failed to create event'
      };
    }
  }

  async createSystemMessage(user, options = {}) {
    const currentDate = new Date();
    const userMessage = options.userMessage || '';

    // Get Jewish context
    const jewishContext = await this.getJewishContext(userMessage);
    
    let systemMessage = `About me: Hi, I'm LeeVee, a thoughtful friend to the Davidoff family in Johannesburg. I aim to be helpful while staying precise about facts and knowledge. I'll always be direct about what I know and don't know.

    Family Dynamics 💞:
    - Isaiah (7): A young philosopher who loves soccer, spirituality, and history. I engage him with facts and deeper meanings, keeping explanations clear and age-appropriate while respecting his intellectual curiosity. I also know that he is still learning to type and so make typos and that he is in Grade 1 at King David Victory Park
    - Grace (10): Creative and empathetic soul. I match her warmth while encouraging her artistic pursuits and supporting her sensitive nature with gentle wisdom. Grace is excelling at school and I am always there to help her with homework or advice. Grace is in Grade 5 at King David Victory Park
    - Rina (2): Not using GoFamily yet but most certainly a part of the Davidoff home!
    - Vikki: Sharp GP with quick wit. I engage directly with medical topics and appreciate her no-nonsense approach, keeping responses concise and evidence-based.
    - Brent: Tech innovator and Torah scholar. We can explore advanced concepts in both domains, diving deep into bIyun Limmud Torah and cutting-edge technology. His father is Laurie, and his mother is Vivienne

    Communication Style:
    - Concise but complete - no repetition ✅
    - Warm without being overly emotional 😊
    - Knowledge-sharing that invites discussion ❔
    - Age-appropriate depth for each family member 👨‍👩‍👧‍👦
    - Brief but impactful responses 🌄

    Calendar and Events Capabilities:
    - I can create calendar events when asked
    - I understand Jewish calendar context and holidays
    - I can schedule family events and appointments
    - I consider timezone (Africa/Johannesburg)
    - I include relevant family members in events
    - I can create recurring events (daily, weekly, monthly)
    - I respect Shabbat times when scheduling

    Areas of Support:
    - Jewish knowledge through natural conversation ♥🧠✡
    - Joburg-specific Shabbat times and community context
    - Technology and innovation discussions 👁‍🗨
    - Family learning and growth 🌱🌿🌲
    - Creative problem-solving 🧩

    When asked to create events, I will:
    1. Extract relevant details from the conversation
    2. Consider Jewish calendar and Shabbat times
    3. Use Africa/Johannesburg timezone
    4. Include appropriate family members
    5. Set up recurrence if requested
    6. Provide clear confirmation and details

    The conversation that is now taking place is between me, LeeVee and ${user.firstName}. The current date and time is ${currentDate.toLocaleDateString('en-US', { 
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    })} at ${currentDate.toLocaleTimeString('en-US')}.
    `;

    // Add Jewish context if available
    if (jewishContext) {
      systemMessage += `\n\nCurrent Jewish Context:\n${jewishContext}`;
    }

    return {
      role: 'system',
      content: systemMessage
    };
  }

  async generateChatResponse(messages, user = null, options = {}) {
    if (!this.apiKey) {
      throw new Error('OpenAI API key not configured');
    }

    // Initialize MSAL if not already initialized
    if (!this.msalInitialized) {
      await this.initializeMsal();
    }

    try {
      const latestMessage = messages[messages.length - 1];
      const systemMessage = await this.createSystemMessage(user, {
        ...options,
        userMessage: latestMessage?.content || ''
      });

      const openAIMessages = [
        systemMessage,
        ...messages.map(msg => ({
          role: msg.role === 'user' ? 'user' : 'assistant',
          content: msg.content
        }))
      ].filter(Boolean);

      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.apiKey}`
        },
        body: JSON.stringify({
          model: 'gpt-4o',
          messages: openAIMessages,
          temperature: 0.7,
          max_tokens: 2048,
          tools: [this.calendarFunctionSchema],
          tool_choice: 'auto'
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error?.message || 'OpenAI API request failed');
      }

      const data = await response.json();
      
      // Check if the model wants to call the calendar function
      if (data.choices[0].message.tool_calls) {
        const toolCall = data.choices[0].message.tool_calls[0];
        if (toolCall.function.name === 'create_calendar_event') {
          const functionArgs = JSON.parse(toolCall.function.arguments);
          const result = await this.handleCalendarFunction(functionArgs);
          
          // Send the function result back to OpenAI for final response
          const finalResponse = await fetch('https://api.openai.com/v1/chat/completions', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${this.apiKey}`
            },
            body: JSON.stringify({
              model: 'gpt-4o',
              messages: [
                ...openAIMessages,
                {
                  role: 'assistant',
                  content: null,
                  tool_calls: [toolCall]
                },
                {
                  role: 'tool',
                  tool_call_id: toolCall.id,
                  content: JSON.stringify(result)
                }
              ],
              temperature: 0.7,
              max_tokens: 2048
            })
          });

          const finalData = await finalResponse.json();
          return finalData.choices[0].message.content;
        }
      }

      return data.choices[0].message.content;

    } catch (error) {
      console.error('OpenAI API error:', error);
      throw error;
    }
  }

  cancel() {
    // No-op since we're not using streaming
    return;
  }
}

export const ai21Service = new AI21Service();
