import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Trophy, Star, Book, Volume2, BookOpen, 
  Sparkles, Medal, Zap, Heart, Award, Battery, Loader2 ,CheckCircle, XCircle, ChevronLeft, ChevronRight 
} from 'lucide-react';
import { useVoice } from '../services/voiceService';
import TopNavigation from '../components/ui/TopNavigation';
import { useAuth } from '../contexts/AuthContext';
import { firebaseService } from '../services/data/firebaseService';
import { 
  getNextQuestion, 
  getHighScores,
  saveHighScore,
  topics 
} from './knowledgeBase';


// Constants
const ENERGY_COSTS = {
  WRONG_ANSWER: 20,  // Cost for wrong answers
  AUDIO_ASSIST: 5    // Cost for using text-to-speech
};

const STREAK_BONUSES = {
  CORRECT: 50,
  READING: 75
};

// Home Screen Component
const HomeScreen = ({ onStartGame, highScores }) => {
  const [isHovering, setIsHovering] = useState(false);
  const { speak } = useVoice();

  const handleStartClick = async () => {
    await speak("Let's play Soccer Challenge!");
    onStartGame();
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="text-center max-w-2xl mx-auto"
    >
      {/* Title with arcade animation */}
      <motion.h1 
        className="text-5xl font-bold mb-8 relative"
        animate={{ 
          textShadow: [
            "0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073",
            "0 0 10px #fff, 0 0 20px #e60073, 0 0 30px #e60073",
            "0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073"
          ]
        }}
        transition={{
          duration: 2,
          repeat: Infinity,
          repeatType: "reverse"
        }}
      >
        <span className="bg-gradient-to-r from-blue-600 via-purple-600 to-blue-600 
                       bg-clip-text text-transparent">
          Soccer iKnow Challenge
        </span>
        <motion.div
          className="absolute -top-4 -left-4"
          animate={{
            rotate: [0, 360],
            scale: [1, 1.2, 1]
          }}
          transition={{
            duration: 5,
            repeat: Infinity,
            ease: "linear"
          }}
        >
          ⚽
        </motion.div>
      </motion.h1>

      
{/* Start Button */}
<motion.button
        whileHover={{ 
          scale: 1.05,
          boxShadow: "0 0 25px rgb(168 85 247 / 0.5)"
        }}
        whileTap={{ scale: 0.95 }}
        onHoverStart={() => setIsHovering(true)}
        onHoverEnd={() => setIsHovering(false)}
        onClick={handleStartClick}
        className="relative px-8 py-4 text-2xl font-bold text-white 
                   bg-gradient-to-r from-blue-600 to-purple-600 rounded-2xl 
                   shadow-lg overflow-hidden"
      >
        <span className="relative z-10">START CHALLENGE</span>
        <motion.div
          className="absolute inset-0 bg-gradient-to-r from-blue-400 to-purple-400"
          animate={{
            scale: isHovering ? [1, 1.2, 1.1] : 1,
            opacity: isHovering ? [0.5, 0.8, 0.5] : 0
          }}
          transition={{
            duration: 1,
            repeat: Infinity,
            repeatType: "reverse"
          }}
        />
      </motion.button>
      {/* High Scores */}
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2 }}
        className="mb-8 bg-white/10 backdrop-blur-md rounded-xl p-6"
      >
        <h2 className="text-2xl font-bold mb-4 text-purple-600 dark:text-purple-400">
          Top Champions
        </h2>
        <div className="space-y-3">
          {highScores.slice(0, 5).map((score, index) => (
            <motion.div
              key={index}
              initial={{ x: -50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ delay: index * 0.1 }}
              className={`flex justify-between items-center p-3 rounded-lg
                         ${index === 0 ? 'bg-yellow-100 dark:bg-yellow-900/50' : 
                                       'bg-white/5 dark:bg-gray-800/50'}`}
            >
              <div className="flex items-center gap-2">
                {index === 0 && <Trophy className="w-5 h-5 text-yellow-500" />}
                <span className="font-bold">{index + 1}.</span>
                <span>{score.name || 'Isaiah 🦸‍♂️'}</span>
                </div>
              <div className="flex items-center gap-2">
                <span className="font-bold">{score.questions} Questions</span>
                <span className="text-sm text-gray-500">({score.points} pts)</span>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>

        
    </motion.div>
  );
};

// Countdown Component
const Countdown = ({ onComplete }) => {
  const [count, setCount] = useState(3);

  useEffect(() => {
    if (count > 0) {
      const timer = setTimeout(() => setCount(count - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      onComplete();
    }
  }, [count, onComplete]);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black/50 backdrop-blur-sm">
      <motion.div
        key={count}
        initial={{ scale: 2, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0, opacity: 0 }}
        className="text-9xl font-bold text-white"
      >
        {count === 0 ? "GO!" : count}
      </motion.div>
    </div>
  );
};
const TTSButton = ({ text, onSpeak }) => {
    const [speechState, setSpeechState] = useState('idle'); // 'idle', 'loading', 'speaking'
    const { speak, isSpeaking, stopSpeaking } = useVoice(); // Assuming useVoice hook exists
  
    const handleTTS = async () => {
      // If currently speaking, stop speech
      if (speechState === 'speaking') {
        stopSpeaking();
        setSpeechState('idle');
        return;
      }
  
      try {
        // Set to loading state
        setSpeechState('loading');
        
        // Attempt to speak
        await speak(text, {
          onStart: () => setSpeechState('speaking'),
          onEnd: () => setSpeechState('idle'),
          onError: () => setSpeechState('idle')
        });
      } catch (error) {
        console.error('Speech error:', error);
        setSpeechState('idle');
      }
    };
  
    // Determine icon based on speech state
    const getIcon = () => {
      switch (speechState) {
        case 'loading':
          return <Loader2 className="w-4 h-4 animate-spin" />;
        case 'speaking':
          return <XCircle className="w-4 h-4" />;
        default:
          return <Volume2 className="w-4 h-4" />;
      }
    };
  
    return (
      <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={handleTTS}
        disabled={speechState === 'loading'}
        className={`
          p-2 rounded-full transition-colors duration-200
          ${speechState === 'speaking' 
            ? 'bg-blue-100 dark:bg-blue-900/50' 
            : 'hover:bg-gray-100 dark:hover:bg-gray-700'
          }
          ${speechState === 'loading' ? 'opacity-50 cursor-wait' : ''}
        `}
      >
        {getIcon()}
      </motion.button>
    );
  };
// ... continuing from part 1
const AnswerReview = ({ questions, onClose }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const { speak } = useVoice();
    
    const currentQuestion = questions[currentIndex];
    
    const handleReadAnswer = async (text) => {
      await speak(text);
    };
  
    const handleNext = () => {
      if (currentIndex < questions.length - 1) {
        setCurrentIndex(currentIndex + 1);
      }
    };
  
    const handlePrevious = () => {
      if (currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
      }
    };
  
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg"
      >
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-xl font-bold">Review Answers</h3>
          <div className="text-sm text-gray-500">
            Question {currentIndex + 1} of {questions.length}
          </div>
        </div>
  
        <AnimatePresence mode="wait">
          <motion.div
            key={currentIndex}
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            className="space-y-6"
          >
            {/* Question */}
            <div className="flex items-start justify-between">
              <div className="flex-1">
                <p className="text-lg font-medium">{currentQuestion.text}</p>
                {currentQuestion.isCorrect ? (
                  <div className="flex items-center gap-2 text-green-600 mt-2">
                    <CheckCircle className="w-5 h-5" />
                    <span>Correct!</span>
                  </div>
                ) : (
                  <div className="flex items-center gap-2 text-red-600 mt-2">
                    <XCircle className="w-5 h-5" />
                    <span>Incorrect</span>
                  </div>
                )}
              </div>
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => handleReadAnswer(currentQuestion.text)}
                className="p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                <Volume2 className="w-6 h-6" />
              </motion.button>
            </div>
  
            {/* Answer Options */}
            <div className="space-y-3">
              {currentQuestion.options.map((option, index) => (
                <div
                  key={index}
                  className={`p-4 rounded-lg flex items-center justify-between
                    ${index === currentQuestion.correct 
                      ? 'bg-green-100 dark:bg-green-900/50' 
                      : index === currentQuestion.selectedAnswer && !currentQuestion.isCorrect
                      ? 'bg-red-100 dark:bg-red-900/50'
                      : 'bg-gray-100 dark:bg-gray-800'}`}
                >
                  <span>{option}</span>
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => handleReadAnswer(option)}
                    className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700"
                  >
                    <Volume2 className="w-4 h-4" />
                  </motion.button>
                </div>
              ))}
            </div>
  
            {/* Explanation */}
            {currentQuestion.explanation && (
              <div className="mt-4 p-4 bg-blue-50 dark:bg-blue-900/30 rounded-lg">
                <div className="flex justify-between items-start">
                  <p className="text-sm">{currentQuestion.explanation}</p>
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => handleReadAnswer(currentQuestion.explanation)}
                    className="ml-2 p-2 rounded-full hover:bg-blue-100 dark:hover:bg-blue-800"
                  >
                    <Volume2 className="w-4 h-4" />
                  </motion.button>
                </div>
              </div>
            )}
          </motion.div>
        </AnimatePresence>
  
        {/* Navigation Buttons */}
        <div className="flex justify-between items-center mt-8">
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={handlePrevious}
            disabled={currentIndex === 0}
            className={`p-2 rounded-full ${currentIndex === 0 ? 'text-gray-400' : 'hover:bg-gray-100 dark:hover:bg-gray-700'}`}
          >
            <ChevronLeft className="w-6 h-6" />
          </motion.button>
  
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={onClose}
            className="px-6 py-2 bg-blue-600 text-white rounded-lg font-medium"
          >
            Done Reviewing
          </motion.button>
  
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={handleNext}
            disabled={currentIndex === questions.length - 1}
            className={`p-2 rounded-full ${currentIndex === questions.length - 1 ? 'text-gray-400' : 'hover:bg-gray-100 dark:hover:bg-gray-700'}`}
          >
            <ChevronRight className="w-6 h-6" />
          </motion.button>
        </div>
      </motion.div>
    );
  };
  

// Status Bar Component
const StatusBar = ({ energy, score, streaks, className = '' }) => {
    return (
      <div className={`flex items-center justify-between p-4 rounded-xl 
                       bg-white/10 backdrop-blur-md ${className}`}>
        {/* Energy Bar */}
        <div className="flex-1 mr-4">
          <div className="flex items-center justify-between mb-1">
            <span className="text-sm font-medium">Energy</span>
            <span className="text-sm">{Math.round(energy)}%</span>
          </div>
          <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
            <motion.div
              className="h-full"
              animate={{
                width: `${energy}%`,
                backgroundColor: energy > 60 ? '#22c55e' : energy > 30 ? '#eab308' : '#ef4444'
              }}
            />
          </div>
        </div>
  
        {/* Streaks */}
        <div className="flex gap-2">
          {streaks.correct > 0 && (
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              className="px-3 py-1 bg-yellow-500 text-white rounded-full 
                         flex items-center gap-1"
            >
              <Star className="w-4 h-4" />
              <span>x{streaks.correct}</span>
            </motion.div>
          )}
          {streaks.reading > 0 && (
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              className="px-3 py-1 bg-purple-500 text-white rounded-full 
                         flex items-center gap-1"
            >
              <BookOpen className="w-4 h-4" />
              <span>x{streaks.reading}</span>
            </motion.div>
          )}
        </div>
  
        {/* Score */}
        <div className="ml-4 text-2xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 
                        bg-clip-text text-transparent">
          {score.toLocaleString()}
        </div>
      </div>
    );
  };
  
  const LearnView = () => {
    const [gameState, setGameState] = useState('home');
    const [energy, setEnergy] = useState(100);
    const [score, setScore] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [answeredQuestions, setAnsweredQuestions] = useState([]);
    const [streaks, setStreaks] = useState({ correct: 0, reading: 0 });
    const { speak } = useVoice();
    const { user } = useAuth();
    const [highScores, setHighScores] = useState([]);
      // Subscribe to high scores
  useEffect(() => {
    const unsubscribe = firebaseService.subscribeToHighScores((scores) => {
      setHighScores(scores);
    });

    return () => unsubscribe && unsubscribe();
  }, []);
  const QuestionCard = ({ question, onAnswer, onTTS }) => {
    const [isAnswerLocked, setIsAnswerLocked] = useState(false);
    const { speak, isSpeaking, isProcessing } = useVoice();
    const [showExplanation, setShowExplanation] = useState(false);
  
    const handleAnswerSelect = (index) => {
      if (isAnswerLocked) return;
      setIsAnswerLocked(true);
      
      // Call parent handler immediately
      onAnswer(index);
      
      // Show explanation if exists
      if (question.explanation) {
        setShowExplanation(true);
      }
    };
  
    // Reset state when question changes
    useEffect(() => {
      setIsAnswerLocked(false);
      setShowExplanation(false);
    }, [question]);
  
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg"
      >
        <div className="flex justify-between items-start mb-6">
          <h2 className="text-xl font-medium flex-1 pr-4">
            {question.text}
          </h2>
          <TTSButton text={question.text} onSpeak={onTTS} />
        </div>
  
        <div className="space-y-3">
          {question.options.map((option, index) => (
            <motion.div
              key={index}
              initial={false}
              animate={{
                scale: isAnswerLocked && index === question.selectedAnswer ? [1, 1.02, 1] : 1,
                transition: { duration: 0.2 }
              }}
            >
              <div className="flex items-center gap-2">
                <motion.button
                  whileHover={{ scale: !isAnswerLocked ? 1.02 : 1 }}
                  whileTap={{ scale: !isAnswerLocked ? 0.98 : 1 }}
                  onClick={() => handleAnswerSelect(index)}
                  disabled={isAnswerLocked}
                  className={`
                    flex-1 p-4 rounded-lg text-left transition-colors
                    ${!isAnswerLocked 
                      ? 'hover:bg-blue-50 dark:hover:bg-blue-900/50 cursor-pointer' 
                      : index === question.correct
                        ? 'bg-green-100 dark:bg-green-900/50'
                        : index === question.selectedAnswer
                          ? 'bg-red-100 dark:bg-red-900/50'
                          : 'bg-gray-100 dark:bg-gray-800'
                    }
                    ${isAnswerLocked ? 'cursor-not-allowed' : ''}
                  `}
                >
                  {option}
                </motion.button>
                
                <TTSButton text={option} onSpeak={onTTS} />
              </div>
            </motion.div>
          ))}
        </div>
  
        {showExplanation && question.explanation && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            className="mt-6 p-4 bg-blue-50 dark:bg-blue-900/30 rounded-lg"
          >
            <div className="flex justify-between items-start">
              <p className="text-sm">{question.explanation}</p>
              <TTSButton text={question.explanation} onSpeak={onTTS} />
            </div>
          </motion.div>
        )}
      </motion.div>
    );
  };
  
  const GameOverScreen = ({ score, questionsAnswered, onPlayAgain, highScores }) => {
    const [showingReview, setShowingReview] = useState(false);
    const [hasAnnouncedScore, setHasAnnouncedScore] = useState(false);
    const { speak } = useVoice();
    const { user } = useAuth();
  
    const isHighScore = score > 0 && score > (highScores[highScores.length - 1]?.score || 0);  
    const announceScore = useCallback(async () => {
      if (!hasAnnouncedScore) {
        await speak(`Game Over! You scored ${score} points and answered ${questionsAnswered.length} questions! Would you like to review your answers?`);
        setHasAnnouncedScore(true);
      }
    }, [score, questionsAnswered.length, hasAnnouncedScore, speak]);

    
  
    if (showingReview) {
      return (
        <AnswerReview 
          questions={questionsAnswered} 
          onClose={() => setShowingReview(false)} 
        />
      );
    }
    
  
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="text-center"
      >
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          className="mb-8"
        >
          <Trophy className="w-24 h-24 mx-auto text-yellow-500 mb-4" />
          <h2 className="text-4xl font-bold mb-2">Game Over!</h2>
          <div className="text-2xl">
            <p>Final Score: {score}</p>
            <p>Questions Answered: {questionsAnswered.length}</p>
          </div>
        </motion.div>
  
        {/* Stats Breakdown */}
        <div className="mb-8 bg-white/10 backdrop-blur-md rounded-xl p-6">
          <h3 className="text-xl font-bold mb-4">Performance Stats</h3>
          <div className="grid grid-cols-2 gap-4">
            <div className="p-4 bg-green-100 dark:bg-green-900/30 rounded-lg">
              <div className="text-2xl font-bold text-green-700 dark:text-green-300">
                {questionsAnswered.length > 0 
                  ? Math.round((questionsAnswered.filter(q => q.isCorrect).length / questionsAnswered.length) * 100)
                  : 0}%
              </div>
              <div className="text-sm text-green-600 dark:text-green-400">Accuracy</div>
            </div>
            
            <div className="p-4 bg-blue-100 dark:bg-blue-900/30 rounded-lg">
              <div className="text-2xl font-bold text-blue-700 dark:text-blue-300">
                {questionsAnswered.length > 0
                  ? Math.round(score / questionsAnswered.length)
                  : 0}
              </div>
              <div className="text-sm text-blue-600 dark:text-blue-400">Avg Points per Question</div>
            </div>
          </div>
        </div>
        {isHighScore && (
  <motion.div
    initial={{ scale: 0 }}
    animate={{ scale: 1 }}
    className="mb-8 p-6 bg-yellow-100 dark:bg-yellow-900/30 rounded-xl"
  >
    <Sparkles className="w-12 h-12 mx-auto text-yellow-500 mb-2" />
    <h3 className="text-xl font-bold text-yellow-800 dark:text-yellow-200">
      New High Score!
    </h3>
  </motion.div>
)}
  
        <div className="flex justify-center gap-4">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={announceScore}
            className="px-6 py-3 bg-blue-600 text-white rounded-xl font-medium"
          >
            Announce Score
          </motion.button>
  
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setShowingReview(true)}
            className="px-6 py-3 bg-purple-600 text-white rounded-xl font-medium"
          >
            Review Answers
          </motion.button>
          
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={onPlayAgain}
            className="px-8 py-4 bg-gradient-to-r from-blue-600 to-purple-600 
                       text-white rounded-xl font-bold text-xl shadow-lg"
          >
            Play Again
          </motion.button>
        </div>
      </motion.div>
    );
  };
  
    // Game logic functions
    const startGame = () => {
      setGameState('countdown');
    };
  
    const initializeGame = () => {
      setGameState('playing');
      setEnergy(100);
      setScore(0);
      setStreaks({ correct: 0, reading: 0 });
      setAnsweredQuestions([]);
      loadNextQuestion();
    };
  
    const handleAnswer = async (selectedAnswer) => {
      if (!currentQuestion) return;
    
      const isCorrect = selectedAnswer === currentQuestion.correct;
    
      // Handle incorrect answer penalties
      if (!isCorrect) {
        setEnergy(prev => {
          const newEnergy = Math.max(0, prev - ENERGY_COSTS.WRONG_ANSWER);
          if (newEnergy === 0) {
            endGame();
            return newEnergy;
          }
          return newEnergy;
        });
        setStreaks(prev => ({ ...prev, correct: 0 }));
      }
    
      // Handle correct answer rewards
      if (isCorrect) {
        setStreaks(prev => {
          const newStreaks = {
            correct: prev.correct + 1,
            reading: prev.reading
          };
    
          if (newStreaks.correct % 3 === 0) {
            setEnergy(e => Math.min(100, e + STREAK_BONUSES.CORRECT));
            setScore(s => s + STREAK_BONUSES.CORRECT);
            speak("Bonus streak! Energy boost!");
          }
    
          return newStreaks;
        });
    
        // Calculate and add points
        const basePoints = 100;
        const streakMultiplier = Math.floor(streaks.correct / 3) + 1;
        const readingBonus = streaks.reading >= 3 ? STREAK_BONUSES.READING : 0;
        
        const pointsGained = (basePoints * streakMultiplier) + readingBonus;
        setScore(prev => prev + pointsGained);
      }
    
      // Record the answer
      setAnsweredQuestions(prev => [
        ...prev,
        {
          ...currentQuestion,
          selectedAnswer,
          isCorrect,
          usedAudio: false
        }
      ]);
    
      // Load next question with a slight delay
      setTimeout(() => {
        if (energy <= ENERGY_COSTS.WRONG_ANSWER && !isCorrect) {
          endGame();
        } else {
          loadNextQuestion();
        }
      }, 1500);
    };
  
    const handleReadQuestion = async () => {
      setEnergy(prev => Math.max(0, prev - ENERGY_COSTS.AUDIO_ASSIST));
      setStreaks(prev => ({ ...prev, reading: 0 }));
      await speak(currentQuestion.text);
    };
  
    const loadNextQuestion = useCallback(() => {
      const nextQuestion = getNextQuestion(answeredQuestions, streaks.correct);
      console.log('Current Question:', currentQuestion?.id);
      console.log('Next Question:', nextQuestion?.id);
      console.log('Answered Questions:', answeredQuestions.map(q => q.id));
      
      if (nextQuestion) {
        if (nextQuestion.id === currentQuestion?.id) {
          console.warn('Same question loaded twice!');
          // Try to get another question
          const retryQuestion = getNextQuestion(answeredQuestions, streaks.correct);
          if (retryQuestion && retryQuestion.id !== currentQuestion?.id) {
            setCurrentQuestion(retryQuestion);
          } else {
            endGame();
          }
        } else {
          setCurrentQuestion(nextQuestion);
        }
      } else {
        endGame();
      }
    }, [answeredQuestions, streaks.correct, currentQuestion]);
  
// Update endGame function
const endGame = async () => {
  // Prevent multiple endGame calls
  if (gameState === 'gameOver') return;
  
  setGameState('gameOver');

  // Calculate final stats
  const correctAnswers = answeredQuestions.filter(q => q.isCorrect).length;
  const finalScore = {
    points: score,
    questions: answeredQuestions.length,
    correct: correctAnswers,
    name: user?.email?.split('@')[0] || 'Anonymous',
    timestamp: new Date().toISOString()
  };

  if (user) {
    try {
      // Only save if they got at least one correct answer
      if (correctAnswers > 0) {
        const updatedScores = await firebaseService.saveHighScore(user.uid, finalScore);
        setHighScores(updatedScores);
      }
    } catch (error) {
      console.error('Error saving score:', error);
    }
  }
};
    return (
      <div className="min-h-screen bg-gradient-to-b from-blue-50 to-purple-50 
                      dark:from-blue-900 dark:to-purple-900">
        <TopNavigation />
        
        <div className="max-w-4xl mx-auto px-4 py-8">
          <AnimatePresence mode="wait">
            {gameState === 'home' && (
              <HomeScreen 
                onStartGame={startGame} 
                highScores={highScores}
              />
            )}
  
            {gameState === 'countdown' && (
              <Countdown onComplete={initializeGame} />
            )}
  
            {gameState === 'playing' && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <StatusBar 
                  energy={energy}
                  score={score}
                  streaks={streaks}
                  className="mb-6"
                />
                <QuestionCard
                  question={currentQuestion}
                  onAnswer={handleAnswer}
                  onTTS={handleReadQuestion}
                />
              </motion.div>
            )}
  
            {gameState === 'gameOver' && (
              <GameOverScreen
                score={score}
                questionsAnswered={answeredQuestions}
                onPlayAgain={startGame}
                highScores={highScores}
              />
            )}
          </AnimatePresence>
        </div>
      </div>
    );
  };
  
  export default LearnView;