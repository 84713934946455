// services/voiceService.js
import { useState, useEffect, useCallback, useRef } from 'react';

const OPENAI_API_KEY = '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';
const WHISPER_API_URL = 'https://api.openai.com/v1/audio/transcriptions';
const TTS_API_URL = 'https://api.openai.com/v1/audio/speech';

class VoiceService {
  constructor() {
    this.mediaRecorder = null;
    this.audioChunks = [];
    this.currentAudio = null;
    
  }
  
  

  async startRecording() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ 
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true
        } 
      });
      
      const mimeType = MediaRecorder.isTypeSupported('audio/mp4') 
        ? 'audio/mp4' 
        : MediaRecorder.isTypeSupported('audio/wav')
        ? 'audio/wav'
        : 'audio/webm';

      this.audioChunks = [];
      this.mediaRecorder = new MediaRecorder(stream, { mimeType });
      
      this.mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          this.audioChunks.push(event.data);
        }
      };

      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      this.mediaRecorder.start(isMobile ? 500 : 1000);
    } catch (error) {
      console.error('Recording error:', error);
      throw error;
    }
  }

  async stopRecording() {
    if (!this.mediaRecorder) return null;

    return new Promise((resolve, reject) => {
      this.mediaRecorder.onstop = async () => {
        try {
          const audioBlob = new Blob(this.audioChunks, { 
            type: this.mediaRecorder.mimeType 
          });

          if (audioBlob.size === 0) {
            resolve(null);
            return;
          }

          const text = await this.transcribeAudio(audioBlob);
          resolve(text);
        } catch (error) {
          reject(error);
        } finally {
          this.audioChunks = [];
          this.mediaRecorder.stream.getTracks().forEach(track => track.stop());
        }
      };

      this.mediaRecorder.stop();
    });
  }

   async transcribeAudio(audioBlob) {
    try {
      const formData = new FormData();
      formData.append('file', audioBlob, 'recording.mp4');
      formData.append('model', 'whisper-1');
      formData.append('language', 'en');
      formData.append('prompt', 'Howzit, shalom, siddur, Torah, vayechi, truly amazing, bru, baruch Hashem, FIFA world cup, so ya,Rina, Vikki, Gracie, Hello, Isaiah');

      const response = await fetch(WHISPER_API_URL, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${OPENAI_API_KEY}`
        },
        body: formData
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(this.getErrorMessage('transcription', response.status, errorText));
      }

      const data = await response.json();
      return data.text;
    } catch (error) {
      console.error('Transcription error:', error);
      throw new Error(error.message || 'Failed to transcribe audio. Please try again.');
    }
  }

  async speak(text, options = {}) {
    if (this.currentAudio) {
      this.currentAudio.pause();
      this.currentAudio = null;
      return 'stopped';
    }

    try {
      const response = await fetch(TTS_API_URL, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${OPENAI_API_KEY}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          model: 'tts-1',
          input: text,
          voice: options.voice || 'ash',
          speed: options.speed || 1.0,
          response_format: 'mp3'
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(this.getErrorMessage('tts', response.status, errorText));
      }

      const audioBlob = await response.blob();
      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);
      this.currentAudio = audio;

      return new Promise((resolve, reject) => {
        let hasStartedPlaying = false;

        audio.onplay = () => {
          hasStartedPlaying = true;
          resolve('playing');
        };

        audio.onended = () => {
          URL.revokeObjectURL(audioUrl);
          this.currentAudio = null;
          if (hasStartedPlaying) {
            resolve('completed');
          }
        };

        audio.onerror = (error) => {
          URL.revokeObjectURL(audioUrl);
          this.currentAudio = null;
          reject(new Error('Failed to play audio. Please try again.'));
        };

        audio.play().catch(error => {
          reject(new Error('Failed to start audio playback. Please try again.'));
        });
      });
    } catch (error) {
      console.error('Speech error:', error);
      throw error;
    }
  }
// Add this method to the VoiceService class
stopSpeaking() {
  if (this.currentAudio) {
    this.currentAudio.pause();
    this.currentAudio = null;
  }
}
  getErrorMessage(type, status, errorText) {
    const baseMessage = type === 'transcription' 
      ? 'Failed to convert speech to text'
      : 'Failed to convert text to speech';

    switch (status) {
      case 401:
        return `${baseMessage}: Authentication failed. Please check API key.`;
      case 400:
        return `${baseMessage}: Invalid request format. ${errorText}`;
      case 429:
        return `${baseMessage}: Rate limit exceeded. Please try again later.`;
      case 500:
        return `${baseMessage}: Server error. Please try again later.`;
      case 503:
        return `${baseMessage}: Service unavailable. Please try again later.`;
      default:
        return `${baseMessage}: ${errorText || 'Unknown error occurred. Please try again.'}`;
    }
  }
  cleanup() {
    if (this.currentAudio) {
      this.currentAudio.pause();
      this.currentAudio = null;
    }
    if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
      this.mediaRecorder.stop();
      this.mediaRecorder.stream.getTracks().forEach(track => track.stop());
      this.audioChunks = [];
    }
  }

}

export const voiceService = new VoiceService();


export const useVoice = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const audioEndedCallbackRef = useRef(null);

  const startRecording = useCallback(async () => {
    try {
      setError(null);
      await voiceService.startRecording();
      setIsRecording(true);
    } catch (error) {
      setError('Failed to start recording. Please check microphone permissions.');
      console.error('Start recording error:', error);
      throw error;
    }
  }, []);

  

  const stopRecordingAndTranscribe = useCallback(async () => {
    try {
      setError(null);
      const text = await voiceService.stopRecording();
      setIsRecording(false);
      return text;
    } catch (error) {
      setError(error.message);
      console.error('Stop recording error:', error);
      setIsRecording(false);
      return null;
    }
  }, []);

  const speak = useCallback(async (text, options = {}) => {
    try {
      setError(null);
      if (isSpeaking) {
        voiceService.stopSpeaking();
        setIsSpeaking(false);
        setIsProcessing(false);
        return;
      }

      setIsProcessing(true);
      const result = await voiceService.speak(text, options);
      
      if (result === 'playing') {
        setIsProcessing(false);
        setIsSpeaking(true);

        audioEndedCallbackRef.current = () => {
          setIsSpeaking(false);
        };

        if (voiceService.currentAudio) {
          voiceService.currentAudio.addEventListener('ended', audioEndedCallbackRef.current);
        }
      } else {
        setIsProcessing(false);
        setIsSpeaking(false);
      }
    } catch (error) {
      setError(error.message);
      console.error('Speech error:', error);
      setIsProcessing(false);
      setIsSpeaking(false);
    }
  }, [isSpeaking]);

  useEffect(() => {
    return () => {
      if (voiceService.currentAudio && audioEndedCallbackRef.current) {
        voiceService.currentAudio.removeEventListener('ended', audioEndedCallbackRef.current);
      }
      voiceService.cleanup();
    };
  }, []);

  return {
    isRecording,
    isSpeaking,
    isProcessing,
    error,
    startRecording,
    stopRecordingAndTranscribe,
    speak
  };
};