// src/views/AuthCallback.jsx
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ai21Service } from '../services/ai/ai21Service';
import { Loader2 } from 'lucide-react';

const AuthCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleCallback = async () => {
      try {
        // Get the stored return path or default to home
        const returnPath = sessionStorage.getItem('authReturnPath') || '/';

        // Check if there's a pending calendar event
        const pendingEventStr = sessionStorage.getItem('pendingCalendarEvent');
        if (pendingEventStr) {
          const pendingEvent = JSON.parse(pendingEventStr);
          
          // Only process if the event is less than 5 minutes old
          const eventAge = Date.now() - pendingEvent.timestamp;
          if (eventAge < 300000) { // 5 minutes in milliseconds
            // Create the event
            await ai21Service.handleCalendarFunction(pendingEvent.eventDetails);
          }
          
          // Clear the pending event regardless
          sessionStorage.removeItem('pendingCalendarEvent');
        }

        // Clear any stored return path
        sessionStorage.removeItem('authReturnPath');

        // Navigate back to the original location
        navigate(returnPath);
      } catch (error) {
        console.error('Auth callback error:', error);
        navigate('/');
      }
    };

    handleCallback();
  }, [navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-900 to-blue-900">
      <div className="text-center text-white space-y-4">
        <Loader2 className="w-12 h-12 animate-spin mx-auto" />
        <h2 className="text-2xl font-semibold">Completing Authentication...</h2>
        <p className="text-gray-300">Just a moment while we set things up.</p>
      </div>
    </div>
  );
};

export default AuthCallback;