// views/AssistantView.jsx
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../contexts/ThemeContext';
import TopNavigation from '../components/ui/TopNavigation';
import ChatBubble from '../components/chat/ChatBubble';
import InputArea from '../components/chat/InputArea';
import LoadingIndicator from '../components/chat/LoadingIndicator';
import { firebaseService } from '../services/data/firebaseService';
import { ai21Service } from '../services/ai/ai21Service';
import ChatLayout from '../components/layout/ChatLayout';

const AssistantView = () => {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const { theme, isDarkMode, setIsDarkMode } = useTheme();
  const messagesEndRef = useRef(null);
  const containerRef = useRef(null);

  // Subscribe to Firebase messages
  useEffect(() => {
    if (!user) return;

    console.log('Setting up Firebase subscription for user:', user.uid);
    const unsubscribe = firebaseService.subscribeAssistantChat(user.uid, (updatedMessages) => {
      const sortedMessages = updatedMessages.sort((a, b) => {
        const timestampA = a.timestamp ? new Date(a.timestamp).getTime() : 0;
        const timestampB = b.timestamp ? new Date(b.timestamp).getTime() : 0;
        return timestampA - timestampB;
      });

      const processedMessages = sortedMessages.map(message => ({
        ...message,
        userName: message.role === 'assistant' ? 'Assistant' : user.firstName,
        userPhoto: message.role === 'assistant' ? 'https://firebasestorage.googleapis.com/v0/b/chat-app-b7301.appspot.com/o/profile_photos%2FUntitled%20design%20Tom2.jpg?alt=media&token=0ae79906-095c-4445-acfe-d834da8845df' : user.photoURL,
        userId: message.role === 'assistant' ? 'assistant' : user.uid,
        type: message.role === 'assistant' ? 'markdown' : 'text'
      }));

      setMessages(processedMessages);
    });

    return () => unsubscribe();
  }, [user]);

  // Auto-scroll effect
 // Auto-scroll effect
 useEffect(() => {
  if (!messagesEndRef.current || !containerRef.current) return;
  
  // Force scroll to bottom on initial load or when new messages arrive
  const scrollToBottom = () => {
    const scrollContainer = containerRef.current;
    const scrollHeight = scrollContainer.scrollHeight;
    const height = scrollContainer.clientHeight;
    const maxScrollTop = scrollHeight - height;
    
    scrollContainer.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  };

  // Use requestAnimationFrame to ensure DOM is ready
  requestAnimationFrame(scrollToBottom);
}, [messages]);
  useEffect(() => {
    if (messages.length > 0 && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'auto' });
    }
  }, []); 

  // Group messages by user and time
  const groupedMessages = useMemo(() => {
    const groups = [];
    let currentGroup = [];
    
    messages.forEach((message, index) => {
      const prevMessage = messages[index - 1];
      
      const getMessageTime = (msg) => {
        if (!msg?.timestamp) return 0;
        if (msg.timestamp?.seconds) return msg.timestamp.seconds * 1000;
        return new Date(msg.timestamp).getTime();
      };
      
      if (
        !prevMessage ||
        prevMessage.role !== message.role ||
        Math.abs(getMessageTime(message) - getMessageTime(prevMessage)) > 120000
      ) {
        if (currentGroup.length > 0) {
          groups.push(currentGroup);
        }
        currentGroup = [message];
      } else {
        currentGroup.push(message);
      }
      
      if (index === messages.length - 1) {
        groups.push(currentGroup);
      }
    });
    
    return groups;
  }, [messages]);

  const handleSendMessage = async (content) => {
    if (!content.trim() || !user || isLoading) return;

    setIsLoading(true);
    try {
      // Send user message to Firebase
      await firebaseService.sendAssistantMessage(
        user.uid,
        content,
        'user',
        { type: 'text' }
      );

      // Get message history
      const messageHistory = messages.map(msg => ({
        role: msg.role,
        content: msg.content
      }));

      // Add the new user message
      messageHistory.push({ role: 'user', content });

      // Get AI response
      const response = await ai21Service.generateChatResponse(
        messageHistory,
        user
      );

      // Save AI response to Firebase
      await firebaseService.sendAssistantMessage(
        user.uid,
        response,
        'assistant',
        { type: 'markdown' }
      );

    } catch (error) {
      console.error('Error in conversation:', error);
      // Optionally show error message to user
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteMessage = async (messageId) => {
    if (!user || !messageId) return;
    
    try {
      await firebaseService.deleteMessage('assistant', messageId, user.uid);
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <TopNavigation
        theme={theme}
        isDarkMode={isDarkMode}
        setIsDarkMode={setIsDarkMode}
      />
  
      <ChatLayout theme={theme} isDarkMode={isDarkMode}>
      <div 
  ref={containerRef}
  className="h-[calc(100vh-120px)] overflow-y-auto px-4 pb-20 scroll-smooth"
>
          <div className="max-w-3xl mx-auto space-y-2 pt-4">
            <AnimatePresence initial={false}>
              {groupedMessages.map((group, groupIndex) => (
                <motion.div key={group[0].id} className="space-y-1">
                  {group.map((message, messageIndex) => (
                    <ChatBubble
                    key={message.id}
                    message={message}
                    isCurrentUser={message.role === 'user'}
                    isFirstInGroup={messageIndex === 0}
                    isLastInGroup={messageIndex === group.length - 1}
                    theme={theme}
                    isDarkMode={isDarkMode}
                    onDelete={() => handleDeleteMessage(message.id)} // Remove the conditional
                    mode="assistant"
                  />
                  ))}
                </motion.div>
              ))}
            </AnimatePresence>
            
            {isLoading && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="flex justify-center"
              >
                <LoadingIndicator theme={theme} isDarkMode={isDarkMode} />
              </motion.div>
            )}
            <div ref={messagesEndRef} className="h-px" />
          </div>
        </div>

        <InputArea
          onSendMessage={handleSendMessage}
          isLoading={isLoading}
          theme={theme}
          isDarkMode={isDarkMode}
          placeholder="Ask me anything..."
        />
      </ChatLayout>
    </div>
  );
};

export default AssistantView;